<template>
  <div
    class="search-input"
    :class="{ 'add-border': hasLetters && !isConfirm }"
  >
    <FInput
      v-model="data"
      v-bind="$attrs"
      size="small"
      @focus="onFocusInput"
      @blur="onBlurInput"
      @keypress.enter.stop="onEnterPress"
    >
      <template #prefix>
        <AppIcon
          name="search"
          :opacity="0.4"
          size="18px"
          is-img-tag
        />
      </template>
      <template #append>
        <FButton
          v-if="hasLetters"
          :key="isConfirm"
          no-margin
          no-shadow
          is-icon
          size="small"
          :class="{ 'rotate-90': isConfirm, reset: !isConfirm }"
          :type="isConfirm ? 'transparent' : 'primary'"
          :icon="isConfirm ? 'arrow-basic' : 'x-close-small'"
          @click="onAppendClick"
        />
      </template>
    </FInput>
  </div>
</template>

<script setup>
import {
  computed, onBeforeUnmount, ref, watch,
} from 'vue';

import { emitter } from '@/composables/useBus';

const emit = defineEmits(['submit']);

const data = defineModel({ type: String, default: '' });
const isFocused = ref(false);
const isConfirm = ref(true);
const hasLetters = computed(() => data.value?.length > 0);
const isValueFromEmitter = ref(false);

watch(data, (val) => {
  if (!isValueFromEmitter.value) {
    data.value = val ? data.value.replace(/\s/g, '') : '';
  }

  isConfirm.value = !!data.value;
});

const onBlurInput = () => {
  isFocused.value = false;
};

const onFocusInput = () => {
  isFocused.value = true;
};

const onAppendClick = () => {
  if (isConfirm.value) {
    emit('submit', data);
    isConfirm.value = !data.value;
    return;
  }
  data.value = '';
  emit('submit', data);
};

const onEnterPress = () => {
  emit('submit', data);
  isConfirm.value = !data.value;
};

onBeforeUnmount(() => {
  data.value = '';
});

emitter.on('run-search', (val) => {
  isValueFromEmitter.value = true;
  isConfirm.value = true;
  data.value = val;
  onAppendClick();
  setTimeout(() => {
    isConfirm.value = false;
    isValueFromEmitter.value = false;
  }, 10);
});
</script>

<style lang="scss" scoped>
.search-input {
  min-width: 180px;
  &.add-border {
    :deep(.el-input__wrapper) {
      border:1px solid var(--el-input-focus-border-color);
    }
  }

  :deep(.slot-append) {
    right: 0 !important;
    height: 40px;
    width: 40px;

    .el-button {
      height: 40px;
      width: 40px;
    }

    .icon path {
      fill: var(--color-primary);
      @include transition-base('fill');
    }

    .reset {
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      box-shadow: unset;
      .icon path {
        fill: #fff;
        @include transition-base('fill');
      }
    }
  }
}
</style>
