<template>
  <div class="pagination">
    <div class="buttons">
      <FButton
        type="plain"
        size="small"
        class="prev"
        :disabled="page === 1"
        @click="prevPage"
      >
        <AppIcon name="arrow-basic" size="18px" class="rotate-270" />
        <AppText>
          {{ t('component.table.pagination.prevPage') }}
        </AppText>
      </FButton>

      <FButton
        type="plain"
        size="small"
        class="next"
        :disabled="page === pageCount"
        @click="nextPage"
      >
        <AppText>
          {{ t('component.table.pagination.nextPage') }}
        </AppText>
        <AppIcon name="arrow-basic" class="rotate-90" size="18px" />
      </FButton>
    </div>

    <div class="pagination__pages">
      <div
        class="pagination__pages-item"
        :class="{
          'is-active': page === 1,
        }"
        @click="changePage(1)"
      >
        <AppText>
          1
        </AppText>
      </div>
      <div v-if="pagesRange[0] > 2" class="pagination__pages-item points">
        <AppText>
          ...
        </AppText>
      </div>

      <div
        v-for="paginationPage in pagesRange"
        :key="paginationPage"
        class="pagination__pages-item"
        :class="{
          'is-active': page === paginationPage,
        }"
        @click="changePage(paginationPage)"
      >
        <AppText>
          {{ paginationPage }}
        </AppText>
      </div>
      <div v-if="pagesRange.slice(-1)[0] < (pageCount - 1)" class="pagination__pages-item points">
        <AppText>
          ...
        </AppText>
      </div>
      <div
        v-if="pageCount > 1"
        class="pagination__pages-item"
        :class="{
          'is-active': page === pageCount,
        }"
        @click="changePage(pageCount)"
      >
        <AppText>
          {{ pageCount }}
        </AppText>
      </div>
    </div>

    <FInput
      ref="inputPageInput"
      v-model="inputPage"
      :placeholder="t('component.table.pagination.enterPage')"
      hide-shadow
      mask="onlyInteger"
      :is-full-width="false"
      size="small"
      :class="{ 'is-error': isOutOfRange }"
      @keypress.enter.stop="changePage(Number(inputPage))"
    >
      <template #suffix>
        <AppIcon
          v-if="inputPage >= 1"
          class="next-icon rotate-90"
          name="arrow-basic"
          size="18px"
          @click="changePage(Number(inputPage))"
        />
      </template>
    </FInput>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

import { range } from '@/utils/functions';

const emit = defineEmits(['change-page']);

const props = defineProps({
  page: {
    type: Number,
    default: null,
  },
  pageCount: {
    type: Number,
    default: null,
  },
  maxItems: {
    type: Number,
    default: 7,
  },
});

const inputPageInput = ref(null);
const inputPage = ref('');
const isOutOfRange = ref(false);

const changePage = (page) => {
  if (range(1, props.pageCount).includes(page)) {
    emit('change-page', page <= props.pageCount ? page : props.pageCount);
    inputPage.value = '';
    inputPageInput.value.$el.querySelector('.el-input__inner').blur();
  } else {
    isOutOfRange.value = true;

    setTimeout(() => {
      isOutOfRange.value = false;
      inputPage.value = '';
    }, 1000);
  }
};

const nextPage = () => {
  changePage(props.page + 1);
};

const prevPage = () => {
  changePage(props.page - 1);
};

const pagesRange = computed(() => {
  const total = props.maxItems - 2;
  const res = [];

  let start = 2;

  if (props.pageCount > total) {
    start = props.page > 4 ? props.page - 1 : 2;
  }

  if (props.pageCount > total && props.page + total > props.pageCount) {
    start = props.pageCount - total !== 1 ? props.pageCount - total : 2;
  }

  for (let i = start; i < props.pageCount; i++) {
    if (res.length > total - 1) { break; }
    res.push(i);
  }

  return res;
});
</script>

<style scoped lang="scss">
.pagination {
  height: 40px;
  display: flex;
  width: 100%;

  background: var(--color-white);

 .buttons {
    display: flex;
    :deep(.el-button svg path) {
      stroke: none !important;
      fill: var(--color-black-04);
    }
    :deep(.el-button.is-disabled svg path) {
      fill: var(--color-black-02) !important;
    }
    :deep(.el-button:hover svg path) {
      fill: var(--color-black);
    }
  }

  &__pages {
    display: flex;
    background: #F7F7F7;
    height: 40px;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 10px 0 20px;

    &-item {
      cursor: pointer;
      padding: 0 18px;
      min-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #F7F7F7;

      &:hover {
        background: #EDEDED;
        border: 1px solid #EDEDED;
      }

      &.is-active {
        background: #FBF3EC;
        border: 1px solid var(--color-primary);
      }

      &:first-child:hover, &:first-child.is-active {
        border-radius: 4px 0 0 4px;
      }

      &:last-child:hover, &:last-child.is-active {
        border-radius: 0 4px 4px 0;
      }
    }

    .points {
      border: none;
      cursor: unset;
      background: none;
      color: unset;
      @include prevent-selection;
    }
  }

  :deep(.f-input) {
    .el-input-group__append {
      display: none;
    }

    .next-icon {
      cursor: pointer;

      path {
        fill: var(--color-primary) !important;
      }
    }

    .el-input__wrapper {
      box-shadow: none;
      width: 106px;
      background: #F7F7F7;
      border: 1px solid #F7F7F7;
      caret-color: var(--color-primary);
      @include transition-base('background, border');

      .el-input__inner {
        width: 100%;
      }

      &:hover, &.is-focus {
        border: 1px solid var(--color-primary);
        background: var(--color-white);
      }
    }

    &.is-error {
      .el-input__wrapper {
        border: 1px solid var(--color-primary);
        color: var(--color-error);
        background: var(--color-error-01);
      }
    }
  }
}
</style>
