<template>
  <div class="table-nodata">
    <svg
      class="no-data-icon"
      draggable="false"
      :data-src="path"
    />
    <div class="table-nodata-title">
      {{ t('component.table.noRecords') }}
    </div>
    <div class="table-nodata-description" @click="onResetFilters">
      <span v-if="isVisible" class="link reset-filters">
        {{ t('component.table.resetFilters') }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

import { emitter } from '@/composables/useBus';
import { useImageLoader } from '@/composables/useImageLoader';

export default defineComponent({
  setup(props) {
    const { path } = useImageLoader('table');
    const isVisible = ref(props.params?.shouldParamsBeReseted);

    const onResetFilters = () => {
      emitter.emit('no-data-reset-filters');
    };

    return {
      path,
      isVisible,

      onResetFilters,
    };
  },
});
</script>

<!-- eslint-disable-next-line -->
<style lang="scss">
.table-nodata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  padding: 20px;
  text-align: center;

  &-title {
    margin-top: 20px;
    font-size: 13px;
    line-height: 1.5;
  }
  &-description {
    margin-top: 10px;
    font-size: 13px;
    line-height: 1.2;
    color: var(--color-black-05);
  }
}
</style>
