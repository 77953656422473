import { TABLE_TYPES } from '@/common/tableTypes';
import { useSettings } from '@/composables/useSettings';
import { useTablesStore } from '@/store';

import Cell from './Cells/Cell.vue';
import CellTextCopy from './Cells/CellTextCopy.vue';
import CellCrypto from './Cells/CellCrypto.vue';
import CellCryptoText from './Cells/CellCryptoText.vue';
import CellCurrency from './Cells/CellCurrency.vue';
import CellCycleType from './Cells/CellCycleType.vue';
import CellDate from './Cells/CellDate.vue';
import CellDateTime from './Cells/CellDateTime.vue';
import CellPaymentType from './Cells/CellPaymentType.vue';
import CellPerson from './Cells/CellPerson.vue';
import CellPlanType from './Cells/CellPlanType.vue';
import CellStatus from './Cells/CellStatus.vue';
import CellCustom from './Cells/uniqueCells/CellCustom.vue';
import CellEmpty from './Cells/CellEmpty.vue';
import HeaderCell from './HeaderCell.vue';
import NoData from './NoData.vue';

export const components = {
  Cell,
  CellTextCopy,
  CellStatus,
  CellCurrency,
  CellDate,
  CellDateTime,
  CellCrypto,
  CellCryptoText,
  CellPaymentType,
  CellCycleType,
  CellPlanType,
  CellPerson,
  CellEmpty,

  CellCustom,

  HeaderCell,
  NoData,
};

export const getCellRenderer = (type) => {
  if (type === TABLE_TYPES.STATUS) {
    return 'CellStatus';
  }
  if (type === TABLE_TYPES.CURRENCY) {
    return 'CellCurrency';
  }
  if (type === TABLE_TYPES.DATE) {
    return 'CellDate';
  }
  if (type === TABLE_TYPES.DATE_TIME) {
    return 'CellDateTime';
  }
  if (type === TABLE_TYPES.CRYPTO) {
    return 'CellCrypto';
  }
  if (type === TABLE_TYPES.CRYPTO_TEXT) {
    return 'CellCryptoText';
  }
  if (type === TABLE_TYPES.PAYMENT_TYPE) {
    return 'CellPaymentType';
  }
  if (type === TABLE_TYPES.CYCLE) {
    return 'CellCycleType';
  }
  if (type === TABLE_TYPES.PLAN) {
    return 'CellPlanType';
  }
  if (type === TABLE_TYPES.PERSON) {
    return 'CellPerson';
  }
  if (type === TABLE_TYPES.ROW_SELECTION) {
    return 'CellEmpty';
  }
  if (type === 'AdminMerchantDeposit') {
    return 'CellAdminMerchantDeposit';
  }
  if (type === 'Custom') {
    return 'CellCustom';
  }
  if (type === TABLE_TYPES.TEXT_COPY) {
    return 'CellTextCopy';
  }

  return 'Cell';
};

export const useAgGrid = (moduleName) => {
  const tableStore = useTablesStore();

  const getWidth = (column) => tableStore.getTableColumns({
    moduleName,
    columnName: column.name,
    field: 'width',
  });

  const isColumnVisible = (columnName) => tableStore.getTableColumns({
    moduleName,
    columnName,
    field: 'isShow',
  });

  const { dateFormat } = useSettings();

  return {
    getWidth,
    isColumnVisible,
    dateFormat,
  };
};
