import { onBeforeUnmount, onMounted } from 'vue';

export const useCustomTabNavigation = (gridApi, { data, emit }) => {
  const selectableElems = document.querySelector('.header__head .header__options__right');
  const selectedElem = selectableElems?.children && selectableElems?.children[selectableElems.children.length - 1];

  const onLastHeaderItemNavigation = (event) => {
    if (event.key !== 'Tab') {
      return;
    }
    event.preventDefault();

    gridApi.value.ensureIndexVisible(0);

    const firstCol = gridApi.value.getAllDisplayedColumns()[0];
    gridApi.value.ensureColumnVisible(firstCol);
    gridApi.value.setFocusedCell(0, firstCol);
  };

  const onKeyPress = (event) => {
    if (event.key !== 'Enter') {
      return;
    }
    try {
      const modalComponent = document.querySelector('.modal');

      if (!modalComponent) {
        const UUID = document.activeElement.parentElement.getAttribute('row-id');
        const selectedRow = data.value.find((row) => [row.uuid, row.id].includes(UUID));

        if (selectedRow) {
          emit('cell-click', selectedRow);
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      console.error('Some error when using keyboard navigation');
    }
  };

  const customTabNavigation = () => {
    if (selectedElem) {
      selectedElem.addEventListener('keydown', onLastHeaderItemNavigation);
    }
  };

  onMounted(() => {
    window.addEventListener('keypress', onKeyPress);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('keypress', onKeyPress);
    if (selectedElem) {
      selectedElem.removeEventListener('keydown', onLastHeaderItemNavigation);
    }
  });

  return {
    customTabNavigation,
  };
};
