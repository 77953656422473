<template>
  <div class="header">
    <div
      class="header__head"
      :class="{
        'has-statuses': statuses.length,
      }"
    >
      <SearchInput
        v-model="search"
        class="header-search-input"
        :class="{
          'no-margins': !(filters && filters.length),
        }"
        :readonly="!isLoaded"
        :placeholder="t('component.table.search')"
        @submit="onSearchData"
      />

      <div class="filters-section">
        <template v-for="filter in filters" :key="filter.field">
          <FDateRangeDropdown
            v-if="filter.type === 'DATE_RANGE'"
            v-model="filter.modelValue"
            :placeholder="filter.label"
            @update="onFilterChange(filter)"
          />

          <CoinFilter
            v-if="filter.type === 'COINS_SELECT'"
            v-model:coins="filter.modelValue"
            :filter="filter"
            :readonly="!isLoaded"
            @update:coins="onFilterChange(filter)"
          />

          <FilterSelect
            v-if="filter.type === 'SELECT'"
            v-model="filter.modelValue"
            :readonly="!isLoaded"
            :filter="filter"
            max-height="320px"
            @update:model-value="onFilterChange(filter)"
          />
        </template>
        <slot name="top-append" />
      </div>
      <div class="statuses-wrapper">
        <div class="d-flex">
          <FRadioButton
            v-if="statuses.length"
            v-slot="option"
            v-model="status"
            :options="statuses"
          >
            <!-- crutch because of discussion https://linear.app/frl/issue/SWI-2307 -->
            {{ t(`status.${hasCrutchForCompleteStatus && option.value === 'COMPLETE' ? 'CONFIRMED' : option.value}`) }}
          </FRadioButton>
          <slot name="status-aside" />
        </div>
        <div class="header__options__right d-flex align-items-center justify-content-end">
          <SelectShowOnPage v-model="perPage" />
          <SelectColumns
            class="header__options__right__select-columns"
            :status="status"
            :columns="columns"
            :module-name="moduleName"
          />
          <slot name="header-append" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed, reactive, ref, watch, onMounted,
} from 'vue';

import CoinFilter from '@/components/Modules/DataTable/CoinFilter.vue';
import SelectColumns from '@/components/MainTable/Filters/SelectColumns.vue';
import SelectShowOnPage from '@/components/MainTable/Filters/SelectShowOnPage.vue';

import { updateTable, useTablesStore } from '@/store';
import { emitter } from '@/composables/useBus';
import { usePagination } from '@/composables/usePagination';

import SearchInput from './SearchInput.vue';
import FilterSelect from './FilterSelect.vue';

const props = defineProps({
  moduleName: {
    type: String,
    default: '',
  },
  apiPath: {
    type: String,
    default: '',
  },
  columns: {
    type: Array,
    default: () => [],
  },
  filters: {
    type: Array,
    default: () => [],
  },
  statuses: {
    type: Array,
    default: () => [],
  },
  hasCrutchForCompleteStatus: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['data-loaded']);

const {
  search,
  isLoaded,
  perPage,
  getData,
  status,
  items,
} = usePagination(props.apiPath, { moduleName: props.moduleName });

watch(perPage, () => {
  getData({
    page: 1,
  });

  updateTable({
    moduleName: props.moduleName,
    field: 'itemsPerPage',
    value: perPage.value,
  });
});

const onSearchData = () => {
  getData({ page: 1 });
};

const tableStore = useTablesStore();
const table = tableStore.getCurrentTable({ moduleName: props.moduleName });

const initFiltersFromStore = () => {
  props.filters.forEach((f) => {
    const isArrayFields = Array.isArray(f.field);
    if (isArrayFields) {
      f.field.forEach((arrField) => {
        if (f.field.includes(arrField) && table && table[arrField]) {
          f.modelValue?.push(table[arrField]);
        } else {
          // It needs to remove date filters in case when filters were reseted after timezone update
          f.modelValue = null;
        }
      });
    }

    if (!isArrayFields && table && table[f.field]) {
      f.modelValue = table[f.field];
    }
  });
};

const wasStatusChanged = ref(false);
watch(status, (newVal) => {
  wasStatusChanged.value = true;
  status.value = ['all', 'ALL'].includes(newVal) ? null : newVal;
  updateTable({
    moduleName: props.moduleName,
    field: 'status',
    value: newVal,
  });
  getData({ page: 1 });
});

const canFiltersBeingUsed = ref(false);
const onFilterChange = () => {
  const selectedFilters = reactive({});

  const setFilter = (field, value) => {
    selectedFilters[field] = value;
    updateTable({ moduleName: props.moduleName, field, value });
  };

  props.filters.forEach((f) => {
    if (f.type === 'DATE_RANGE') {
      f.field.forEach((fieldName, i) => {
        if (f.modelValue && f.modelValue[i] === undefined) {
          setFilter(fieldName, null);
        } else {
          setFilter(fieldName, f.modelValue && f.modelValue[i]);
        }
      });
    } else {
      setFilter(f.field, f.modelValue);
    }
  });

  if (canFiltersBeingUsed.value) {
    getData({ filters: selectedFilters });
  }
};

onMounted(() => {
  // It needs to avoid double API calls because of strange logic of DatePicker component
  canFiltersBeingUsed.value = true;
});

emitter.on('clear-filters', (filters) => {
  const filterFields = filters.value.map((f) => f[0]);
  props.filters.forEach((f) => {
    f.modelValue = null;

    const isArrayFields = Array.isArray(f.field);

    if (isArrayFields) {
      f.field.forEach((arrField) => {
        updateTable({
          moduleName: props.moduleName,
          field: arrField,
          value: null,
        });
      });
    }

    if (!isArrayFields && filterFields.includes(f.field)) {
      updateTable({
        moduleName: props.moduleName,
        field: f.field,
        value: null,
      });
    }
  });
});

watch(items, () => {
  if (wasStatusChanged.value && items.value) {
    emit('data-loaded', status.value);
  }
  wasStatusChanged.value = false;
});

initFiltersFromStore();

// It needs to remove date filters when entity is created SWI-4186
watch(computed(() => table && table.dateFrom && table.dateTo), () => {
  const wasFiltersRemoved = !Object.prototype.hasOwnProperty.call(table, 'dateFrom') && !Object.prototype.hasOwnProperty.call(table, 'dateTo');

  if (wasFiltersRemoved) {
    props.filters.forEach((f) => {
      if (f.type === 'DATE_RANGE') {
        f.modelValue = null;
      }
    });
  }
});
</script>

<style lang="scss" scoped>
.header{
  width: 100%;
}

.header__head {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .header-search-input {
    flex: 1;
  }

  .statuses-wrapper {
    width: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }

  &.has-statuses {
    .statuses-wrapper {
      width: 100%;
    }
  }
}

.no-margins{
  margin-right: 0 !important;
}

.filters-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
}

.header__options__right {
  gap: 8px;
}

.header__options__right__select-columns {
  :deep(.select-columns__body) {
    padding: 10px 20px;
  }

  :deep(.select-columns__item) {
    margin: 4px 0 !important;
  }
}

:deep(.el-button) {
  margin-bottom: 0;
}
:deep(.f-select--wrapper) {
  margin-bottom: 0;
  .el-input__wrapper {
    height: 40px;
  }
}
:deep(.f-input) {
  margin-bottom: 0;
}

.header {
  width: calc(100% - 10px);
}

:deep(.filters-select) {
  &.has-value {
    .el-input__wrapper {
      background-color: #f7f7f7;
    }
  }
}
:deep(.coin-filter) {
  .has-value {
    background-color: #f7f7f7;
  }
}

</style>
