<template>
  <div
    v-click-outside="() => isOpen = false"
    class="coin-filter"
    tabindex="0"
    @click="isOpen = !isOpen"
  >
    <div
      class="title"
      :class="{
        'has-value': coins.length > 0,
        active: isOpen,
      }"
    >
      <div class="title-icon-wrap">
        <transition name="show">
          <AppIcon
            v-if="title.isPrimary || coins.length === 0"
            :key="title.icon"
            :name="title.icon"
            class="title-icon"
            size="18px"
            :opacity="coins.length === 1 ? 1 : 0.4"
          />
          <FamilyIcon
            v-else
            :icon="title.icon"
            :family-icon="title.familyIcon"
            class="title-icon"
            size="18px"
          />
        </transition>
      </div>

      <transition name="show">
        <AppText :line-height="1.35">
          {{ title.text }}
        </AppText>
      </transition>

      <div class="action-wrap">
        <transition name="show">
          <div v-if="coins.length === 0" class="title-arrow">
            <DropdownArrow :is-open="isOpen" />
          </div>

          <ResetButton v-else @reset="onReset" />
        </transition>
      </div>
    </div>

    <transition name="show-fast">
      <div v-if="isOpen" class="el-popper dropdown-popper coin-filter-popper" @click.stop>
        <div class="dropdown-wrapper">
          <DropdownItem
            v-for="(item, index) in filter.options"
            :key="index"
            class="coin"
            no-border
            size="medium"
            :class="{ active: coins.includes(item.value) }"
            @click="setFilter(item.value)"
          >
            <div class="check">
              <AppIcon name="check" size="8px" class="check-icon" />
            </div>
            <FamilyIcon
              v-if="!item.isPrimary"
              class="coin-icon"
              :icon="item.icon"
              :family-icon="item.familyIcon"
            />
            <AppIcon
              v-else
              :name="item.icon"
              size="28px"
              class="coin-icon"
            />
            <AppText v-if="item.isPrimary">
              {{ item.value === 'ETH_ETH' ? item.shortValue : item.value }}
            </AppText>
            <AppText v-else>
              {{ item.name }} ({{ item.family }})
            </AppText>
          </DropdownItem>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';

import DropdownItem from '@/components/ElementUI/DropdownItem.vue';
import DropdownArrow from '@/components/Icon/DropdownArrow.vue';
import FamilyIcon from '@/components/Icon/FamilyIcon.vue';
import ResetButton from '@/components/Buttons/ResetButton.vue';

import clickOutside from '@/directives/clickOutside';
import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';
import { i18n } from '@/plugins/localization';

export default {
  name: 'CoinFilter',
  components: {
    DropdownArrow,
    DropdownItem,
    ResetButton,
    FamilyIcon,
  },
  directives: {
    clickOutside,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:coins'],
  setup(props, { emit }) {
    const { t } = i18n.global;

    const isOpen = ref(false);
    const coins = ref([]);

    const setFilter = (currency) => {
      if (props.readonly === false) {
        if (coins.value.includes(currency)) {
          coins.value = coins.value.filter((item) => item !== currency);
        } else {
          coins.value.push(currency);
        }

        onModelUpdate(coins.value);
      }
    };

    const title = computed(() => {
      const res = {
        text: t('common.coin'),
        icon: 'coin-crypto',
      };

      if (coins.value.length === 1) {
        const selectedCoin = props.filter.options.find((coin) => coin.value === coins.value[0]);

        res.text = selectedCoin.isPrimary ? selectedCoin.value : selectedCoin.name;
        res.icon = selectedCoin.icon;
        res.isPrimary = selectedCoin.isPrimary;
        res.familyIcon = selectedCoin.familyIcon;

        if (selectedCoin.value === 'ETH_ETH') {
          res.text = selectedCoin.shortValue;
        }
      }

      if (coins.value.length > 1) {
        res.text = `${coins.value.length} selected`;
        res.icon = 'toll-black';
        res.isPrimary = true;
      }

      return res;
    });

    watch(() => props.filter.modelValue, (value) => {
      coins.value = value || [];
    }, { immediate: true });

    const onModelUpdate = (value) => {
      emit('update:coins', value);
    };

    const onReset = () => {
      coins.value = [];
      isOpen.value = false;
      onModelUpdate(coins.value);
    };

    useKeyboardEvent(keys.BACKQUOTE, () => { isOpen.value = false; });

    return {
      setFilter,
      coins,
      title,
      isOpen,
      onReset,
    };
  },
};
</script>

<style lang="scss" scoped>
.coin-filter {
  cursor: pointer;
  position: relative;

  @include focus-visible;

  .title {
    height: 40px;
    border-radius: 4px;
    width: 165px;
    padding: 0 12px;
    border: 1px solid var(--color-black-01);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    background: #fff;
    color: #999;
    @include transition-base('background, color');

    &:hover {
      border: 1px solid var(--color-black-02);
    }

    &.active {
      border: 1px solid var(--color-primary);
    }

    &.has-value {
      color: var(--color-black);
    }

    .title-icon-wrap {
      width: 18px;
      height: 18px;
      margin-right: 12px;
      position: relative;

      .title-icon {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    :deep(.text) {
      flex-grow: 1;
    }

    .action-wrap {
      height: 100%;
      aspect-ratio: 1 / 1;
      position: relative;
      transform: translateX(11px);

      .title-arrow {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        aspect-ratio: 1 / 1;
        padding: 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .coin-filter-popper {
    overflow: hidden;
    position: absolute;
    top: calc(40px + 4px);
    z-index: 4;
    left: 0;
  }

  .coin {
    cursor: pointer;
    width: 205px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    background: #fff;
    @include transition-base(background);

    &:hover {
      background: #FBF3EC;

      .check {
        border: 1px solid var(--color-primary);
      }
    }

    .check {
      width: 16px;
      height: 16px;
      border-radius: 2px;
      border: 1px solid #E6E6E6;
      background: var(--color-white);
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition-base('background, border');

      .check-icon {
        display: none;
        margin-left: 1px;

        :deep(path) {
          fill: var(--color-white);
        }
      }
    }

    .coin-icon {
      margin: 0 12px;
    }

    &.active {
      background: #FBF3EC;

      .check {
        background: var(--color-primary);
        border-color: var(--color-primary);

        .check-icon {
          display: unset;
        }
      }
    }
  }
}
</style>
