<template>
  <FSelect
    ref="selectRef"
    :model-value="filter.modelValue"
    has-arrow
    :has-close-icon="hasCloseIcon"
    :placeholder="filter.label"
    :options="filter.options"
    class="filters-select"
    :class="{
      'has-items-icon': filter.hasItemsIcon,
      'has-icon': filter.modelValue && filterHasIcons,
      'has-value': filter.modelValue,
      'hide-prefix': filter.hidePrefix,
      'is-open': isOpen,
    }"
    :popper-class="popperClass"
    @update:model-value="onUpdate"
    @visible-change="val => isOpen = val"
  >
    <template v-if="filter.modelValue" #prefix>
      <div v-if="filterHasIcons" style="margin-right: 4px;" class="flex-center">
        <AppIcon
          :key="filter.modelValue"
          :name="getIconName"
          size="24px"
        />
      </div>

      <div v-if="hasCloseIcon" class="close-block">
        <ResetButton style="right: 0; left: auto" @click="onUpdate(null)" />
      </div>
    </template>
    <template #option="{ item }">
      <template v-if="filter.field === 'crypto' || filter.hasItemsIcon">
        <div class="d-flex align-items-center">
          <AppIcon :name="item.icon" size="24px" class="mr-4" />
          <span>
            {{ item.name || item.text }}
          </span>
        </div>
      </template>
      <template v-else>
        {{ item.name || item.text }}
      </template>
    </template>
  </FSelect>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';

import ResetButton from '@/components/Buttons/ResetButton.vue';

import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';

export default defineComponent({
  components: {
    ResetButton,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasCloseIcon: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectRef = ref(null);
    const onUpdate = (value) => {
      if (props.readonly === false) {
        emit('update:modelValue', value);
      }
    };

    const filterHasIcons = computed(() => props.filter.options.every((option) => Boolean(option.icon)));
    const getIconName = computed(() => {
      if (props.filter.modelValue) {
        const option = props.filter.options.find((opt) => props.filter.modelValue === opt.value);
        return option.icon;
      }
      return null;
    });

    useKeyboardEvent(keys.BACKQUOTE, () => {
      if (selectRef.value) {
        selectRef.value.$el.querySelector('.el-input__inner').blur();
      }
    });
    const popperClass = computed(() => `table-select-container ${props.filter.hasItemsIcon ? 'wrapper-has-items-icon' : ''}`);

    const isOpen = ref(false);
    return {
      selectRef,
      onUpdate,

      filterHasIcons,
      getIconName,
      popperClass,
      isOpen,
    };
  },
});
</script>

<style scoped lang="scss">
.filters-select {
  max-width: 10rem;
  height: 40px;
  :deep(.el-select .el-input__wrapper) {
    min-height: 40px !important;
    height: 40px;
    border: 1px solid var(--color-black-01);
    &:hover {
      border: 1px solid var(--color-black-02);
    }
  }
  &.is-open {
    :deep(.el-select .el-input__wrapper) {
      border: 1px solid var(--color-primary);
    }
  }
  :deep(.el-input--prefix .el-input__inner) {
    // padding-top: 15px !important;
    &:hover {
      cursor: pointer;
    }
  }
  &.has-icon {
    :deep(.el-input--prefix .el-input__inner) {
      padding-top: 1px;
      padding-left: 50px !important;
      border: unset !important;
    }
  }

  &.hide-prefix {
    :deep(.el-input__prefix) {
      margin-right: 0;
    }
  }

  &.has-items-icon {
    :deep(.el-input--prefix .el-input__inner) {
      padding: unset !important;
    }
    :deep(.el-input__prefix) {
      // width: 46px;
      .icon.elem {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
}
</style>
