<template>
  <div class="wrapper">
    <AgGrid
      v-if="isLoaded"
      ref="aggrid"
      :class="{
        'is-row-default': !isInfoModalActive,
        'has-no-hover': hasNoHover,
      }"
      :is-loading="isLoading"
      :columns="columns"
      :data="data.length ? data : items"
      :module-name="moduleName"
      :table-storage-name="tableStorageName"
      :no-pagination="!isPaginationVisible"
      :sorted-params="sortedParams"
      :current-filters="currentFilters"

      @sort-by="onSort"
      @reset-filters="onResetFilters"
      @cell-click="onRowClick"
      @select-rows="onRowsSelect"
      @grid-ready="$emit('grid-ready')"
    />

    <div class="d-flex">
      <TablePagination
        v-if="isPaginationVisible"
        v-model:page="page"
        :page-count="pageCount"
        class="wrapper-pagination"
        @change-page="changePage"
      />
      <FSpacer />
      <ItemsLength
        :page="page"
        :length="data.length || items.length"
        :page-count="pageCount"
        :per-page="perPage"
        :total-count="totalCount"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import AgGrid from '@/components/Modules/AgGrid';

import { usePagination } from '@/composables/usePagination';
import { useTablesStore } from '@/store';

import ItemsLength from './ItemsLength.vue';
import TablePagination from './TablePagination.vue';

export default {
  name: 'PaginationTable',
  components: {
    AgGrid,
    TablePagination,
    ItemsLength,
  },
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Array,
      default: () => [],
    },
    moduleName: {
      type: String,
      default: '',
    },
    tableStorageName: {
      type: String,
      default: '',
    },
    apiPath: {
      type: String,
      default: '',
    },
    isInfoModalActive: {
      type: Boolean,
      default: true,
    },
    hasNoHover: {
      type: Boolean,
      default: false,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['item-click', 'row-click', 'grid-ready'],
  setup(props, { emit, expose }) {
    const {
      items,
      selectedRows,
      isLoading,
      isLoaded,
      page,
      pageCount,
      totalCount,

      perPage,

      clearFilterParams,
      getData,

      sortBy,
      orderBy,

      computedParams,
    } = usePagination(props.apiPath, { moduleName: props.moduleName });

    const isPaginationVisible = computed(() => items.value.length && pageCount.value >= 2);

    const changePage = (newPage) => {
      if (Number(newPage) !== 0) {
        getData({ page: newPage });
      }
    };

    const onItemClick = (row, column) => {
      emit('item-click', row, column);
    };
    const onRowClick = (item) => {
      emit('row-click', item);
    };

    const onSort = (columnName) => {
      if (!props.isHidden) {
        getData({ sortBy: columnName, isFreshResponse: true });
      }
    };

    const sortedParams = computed(() => ({ sortBy: sortBy.value, orderBy: orderBy.value }));

    const currentFilters = computed(() => {
      const otherParams = ['isFreshResponse', 'shouldResetRequestState', 'page', 'perPage'];
      return Object.entries(computedParams.value)
        .filter((val) => !otherParams.includes(val[0]))
        .filter((val) => ![null, ''].includes(val[1]));
    });

    const onResetFilters = () => {
      clearFilterParams(currentFilters);

      getData({ isFreshResponse: true });
    };

    const tableStore = useTablesStore();
    tableStore.initTable({
      moduleName: props.tableStorageName || props.moduleName,
      columns: props.columns,
    });

    const onRowsSelect = (rows) => {
      selectedRows.value = rows;
    };

    const aggrid = ref(null);
    const setNewColumns = (newColumns) => {
      aggrid.value.setNewColumns(newColumns);
    };
    expose({ setNewColumns });

    return {
      aggrid,

      items,
      isLoading,
      isLoaded,
      page,
      pageCount,
      perPage,
      totalCount,
      changePage,
      isPaginationVisible,

      onItemClick,
      onRowClick,

      onSort,
      sortedParams,

      onResetFilters,
      currentFilters,

      onRowsSelect,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper{
  margin-top: 20px;

  :deep(.pagination), :deep(.items-length) {
    margin-top: 20px;
  }
}

.is-row-default {
  :deep(.ag-root-wrapper .ag-cell) {
    cursor: default;
  }
}

.has-no-hover {
  :deep(.ag-root-wrapper) {
    background-color: var(--color-black-002);
    .ag-row{
      background-color: var(--color-black-002);
      cursor: default;

      &:before {
        background-color: #f5f5f5;
      }

      &:hover {
        cursor: default;
        background-color: var(--color-black-002);
      }
    }

  }

}
</style>
