<template>
  <div
    class="items-length"
    :class="{
      'no-pagination': totalCount === 0 || pageCount === 1,
    }"
  >
    <AppText v-if="totalCount" color="var(--color-black-04)">
      {{ message }}
    </AppText>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import { i18n } from '@/plugins/localization';

export default defineComponent({
  props: {
    page: {
      type: Number,
      default: 0,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 0,
    },
    length: {
      type: Number,
      default: 0,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { t } = i18n.global;
    const firstItemNumber = computed(() => {
      if (props.page === props.pageCount) {
        return props.perPage * (props.pageCount - 1) + 1;
      }
      return props.page * props.length - props.length + 1;
    });

    const lastItemNumber = computed(() => {
      if (props.page === props.pageCount) {
        return props.totalCount;
      }
      return props.page * props.length;
    });

    const message = computed(() => t(
      'component.table.showOnPage',
      {
        period: `${firstItemNumber.value} - ${lastItemNumber.value}`,
        all: props.totalCount,
      },
    ));

    return { message };
  },
});
</script>

<style scoped lang="scss">
.items-length {
  width: 300px;
  text-align: right;

  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 10px;

  &.no-pagination {
    margin-top: 16px !important;
  }
}
</style>
