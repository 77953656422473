import { ref } from 'vue';

export const useImageLoader = (iconName) => {
  const path = ref('');

  if (iconName) {
    try {
      // eslint-disable-next-line
      path.value = require(`@/assets/icons/${iconName}.svg`);
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(`Wrong path for Icon.vue component.\n${e.message}.`);
      }
    }
  } else {
    // eslint-disable-next-line
    path.value = require('@/assets/icons/empty.svg');
  }

  return { path };
};
